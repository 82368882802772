// Here should goes styles when javascript is disable / or browser doesn't support javascript

[data-enter-animation] {
    opacity: 1 !important;
    transform: none !important;
}

.blog {
    &__poster {
        img {
            border: none !important;
        }

        .play {
            display: none !important;
        }
    }
}