[data-enter-animation] {
  opacity: 1 !important;
  transform: none !important;
}

.blog__poster img {
  border: none !important;
}

.blog__poster .play {
  display: none !important;
}
/*# sourceMappingURL=_no-scripts.css.map */
